<template>
  <div class="admin-view-container" v-loading="loading" style="height: 110%">
    <div class="admin-table-search head" style="display: block">
      <i class="el-icon-s-order"></i>
      平台数据
      <el-tooltip class="item" effect="light" content="今日数据：次日凌晨1点进行更替" placement="right">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <!-- 学员 -->
    <div class="admissions content">
      <div class="content-tag">报名学员</div>
      <div class="admissions-flex">
        <div class="admissions-head">今日报名</div>
        <div class="admissions-main">{{ tableData.todayTheAdmissions }}</div>
        <div class="admissions-bottom">昨日：{{ tableData.yesterdayTheAdmissions }}</div>
      </div>
      <div class="admissions-flex">
        <div class="admissions-head">本月报名</div>
        <div class="admissions-main">{{ tableData.monthTheAdmissions }}</div>
        <div class="admissions-bottom">上月：{{ tableData.lastMonthTheAdmissions }}</div>
      </div>
      <div class="admissions-flex">
        <div class="admissions-head">本年报名</div>
        <div class="admissions-main">{{ tableData.yearsTheAdmissions }}</div>
        <div class="admissions-bottom">去年：{{ tableData.lastYearsTheAdmissions }}</div>
      </div>
    </div>
    <!-- 收入 -->
    <div class="admissions content">
      <div class="content-tag">会员服务学员</div>
      <div class="admissions-flex">
        <div class="admissions-head">今日加入会员服务(人)</div>
        <div class="admissions-main">{{ tableData.todayPolicy || 0 }}</div>
        <div class="admissions-bottom">昨日：{{ tableData.yesterdayPolicy || 0 }}</div>
      </div>

      <div class="admissions-flex">
        <div class="admissions-head">本月加入会员服务(人)</div>
        <div class="admissions-main">{{ tableData.monthPolicy || 0 }}</div>
        <div class="admissions-bottom">上月：{{ tableData.lastMonthPolicy || 0 }}</div>
      </div>

      <div class="admissions-flex">
        <div class="admissions-head">本年加入会员服务(人)</div>
        <div class="admissions-main">{{ tableData.yearsPolicy || 0 }}</div>
        <div class="admissions-bottom">去年：{{ tableData.lastYearsPolicy || 0 }}</div>
      </div>
    </div>
    <!-- 收入 -->
    <div class="admissions content">
      <div class="content-tag">收入</div>
      <div class="admissions-flex">
        <div class="admissions-head">今日会员服务(元)</div>
        <div class="admissions-main">{{ tableData.plans1.todayPlan || 0 }}</div>
        <div class="admissions-bottom">昨日：{{ tableData.plans1.yesterdayPlan || 0 }}</div>
      </div>

      <div class="admissions-flex">
        <div class="admissions-head">本月会员服务(元)</div>
        <div class="admissions-main">{{ tableData.plans1.monthThePlan || 0 }}</div>
        <div class="admissions-bottom">上月：{{ tableData.plans1.lastMonthThePlan || 0 }}</div>
      </div>

      <div class="admissions-flex">
        <div class="admissions-head">本年会员服务(元)</div>
        <div class="admissions-main">{{ tableData.plans1.yearsThePlan || 0 }}</div>
        <div class="admissions-bottom">去年：{{ tableData.plans1.lastYearsThePlan || 0 }}</div>
      </div>
    </div>
    <!-- 支出 -->
    <div class="admissions content">
      <div class="content-tag">支出</div>
      <div class="admissions-flex">
        <div class="admissions-head">未提现金额(元)</div>
        <div class="admissions-main">{{ tableData.plans2.undrawn || 0 }}</div>
      </div>

      <div class="admissions-flex">
        <div class="admissions-head">已提现金额(元)</div>
        <div class="admissions-main">{{ tableData.plans2.withdrawn || 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {serHomeReport} from '../../api/index';
export default {
  data() {
    return {
      loading: false,
      tableData: {
        todayTheAdmissions: 0, //	今日招生
        yesterdayTheAdmissions: 0, //	昨日招生
        monthTheAdmissions: 0, //	本月招生
        lastMonthTheAdmissions: 0, //	上月招生
        yearsTheAdmissions: 0, //	本年招生
        lastYearsTheAdmissions: 0, //	去年招生
        remainingSum: 0, //	账户余额
        orderFlowAmount: 0, //	上次充值金额
        plans: [], // - planType 1：会员服务  2：补偿
        counts: [], // 下方表格
        plans1: {
          planType: '',
          todayPlan: '',
          yesterdayPlan: '',
          monthThePlan: '',
          lastMonthThePlan: '',
          yearsThePlan: '',
          lastYearsThePlan: '',
        },
        plans2: {
          planType: '',
          todayPlan: '',
          yesterdayPlan: '',
          monthThePlan: '',
          lastMonthThePlan: '',
          yearsThePlan: '',
          lastYearsThePlan: '',
        },
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    indexMethod(index) {
      if (index == 0) return '今日生成';
      if (index == 1) return '本月生成';
      if (index == 2) return '本年生成';
    },

    getTableData() {
      this.loading = true;
      serHomeReport().then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          res.data.data.plans.forEach((item) => {
            if (item.planType == 1) {
              res.data.data.plans1 = item;
            } else if (item.planType == 2) {
              res.data.data.plans2 = item;
            }
          });
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.head {
  font-size: 20px;
  font-weight: 700;
}
.content {
  position: relative;
  .content-tag {
    position: absolute;
    padding: 0 8px;
    height: 25px;
    background: $--color-primary;
    left: 0;
    top: 10px;
    text-align: center;
    line-height: 25px;
    color: white;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 10;
  }
}
.admissions {
  display: flex;
  padding: 30px 0 50px 0;
  .admissions-flex {
    width: 33%;
    text-align: center;
    .admissions-head {
      height: 30px;
      line-height: 30px;
    }
    .admissions-main {
      height: 60px;
      line-height: 60px;
      font-size: 32px;
      color: $--color-primary;
    }
    .admissions-bottom {
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      color: $--color-info;
    }
  }
}
.plan {
  display: flex;
  align-items: center;
  .plan-balance {
    width: 30%;
    text-align: center;
    .plan-balance-head {
      height: 30px;
      line-height: 30px;
    }
    .plan-balance-main {
      height: 60px;
      line-height: 60px;
      font-size: 32px;
      color: $--color-danger;
    }
    .plan-balance-bottom {
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      color: $--color-info;
    }
  }
  .plan-detail {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    .plan-detail-flex {
      width: 33%;
      text-align: center;
      margin: 20px 0 20px 0;
      .plan-detail-head {
        height: 30px;
        line-height: 30px;
      }
      .plan-detail-main {
        height: 60px;
        line-height: 60px;
        font-size: 32px;
        color: $--color-primary;
      }
      .plan-detail-bottom {
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        color: $--color-info;
      }
    }
  }
}
.exam {
  padding: 30px 0 50px 0;
  .el-table {
    border-radius: 18px;
    margin: 0 auto;
  }
}
</style>
